html {
  height: 100%;
}

body {
  margin: 0;
  height: inherit;
}

#root {
  height: inherit;
}

.App {
  height: inherit;
}
